export default {
  GET(state, data) {
    state.financialInfo = { ...data }
  },
  LIST(state, data) {
    state.financialInfoList = data
  },
  SET_FORM(state, data) {
    state.financialInfoForm = { ...data }
  },
  SET_FORM_CLONE(state, data) {
    state.financialInfoFormClone = JSON.stringify(data)
  },
}
