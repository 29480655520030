import crudActions from '@/libs/storeConstActions'
import store from '@/store'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const getCustomerId = () => store.state.customers?.customer?.id
const endpoint = () => `customers/${getCustomerId()}/financial-info`
const setDefault = (ctx, data) => axios.post(`/customers/${getCustomerId()}/financial-info/${data.id}/set-default`)

const Actions = crudActions(endpoint)

const getAddresses = (ctx, queryParams) => axios.get(`/customers/${getCustomerId()}/addresses`, { params: { ...queryParams, billing: 1 } })

const getContacts = (ctx, queryParams) => axios.get(`/customers/${getCustomerId()}/contacts`, { params: { ...queryParams, billing: 1 } })

const getActiveList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 1 } }
  axios.get(`customers/${getCustomerId()}/financial-info`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getInActiveList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 0 } }
  axios.get(`customers/${getCustomerId()}/financial-info`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  delete data.attachments
  // eslint-disable-next-line no-param-reassign
  data.is_active = false
  axios.put(`customers/${getCustomerId()}/financial-info/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const setActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  delete data.attachments
  // eslint-disable-next-line no-param-reassign
  data.is_active = true
  axios.put(`customers/${getCustomerId()}/financial-info/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})

export default {
  ...Actions,
  setDefault,
  getAddresses,
  getActiveList,
  getInActiveList,
  setInActive,
  setActive,
  getContacts,
}
